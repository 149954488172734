/**
 * DO NOT EDIT THIS PAGE WITHOUT CONSULTING WITH ANALYTICS SQUAD
 *
 * These settings differ from Main Corg, do not just copy + paste over this file!
 */
export default function (adobeLaunchScript, debugEnabled, domainHash) {
  return {
    externalScript: adobeLaunchScript,
    fullFunnelQueryParamName: 'corid',
    campaignParam: 'cmpcor',
    globalVariableName: 'adobeTags',
    linkContainersArray: [
      {
        linkContainerName: 'footer',
        linkContainerSelectors: ['footer'],
      },
      {
        linkContainerName: 'headerLink',
        linkContainerSelectors: ['header'],
      },
      {
        linkContainerName: 'uspLink',
        linkContainerSelectors: ['.header__highlight__stat--link'],
      },
      {
        linkContainerName: 'MobileMenu',
        linkContainerSelectors: ['.mobile-dropdown-menu'],
      },
      {
        linkContainerName: 'toplist',
        linkContainerSelectors: ['.vertical-toplist', '.horizontal-toplist', '#toplist'],
      },
      {
        linkContainerName: 'TopNavigation',
        linkContainerSelectors: ['.m-desktop-atf-quick-links'],
      },
      {
        linkContainerName: 'sideNavigation',
        linkContainerSelectors: ['.o-sidebar'],
      },
      {
        linkContainerName: 'component::Header::Sign-Up',
        linkContainerSelectors: ['.membership-entry__button--sign-up'],
      },
      {
        linkContainerName: 'component::Header::Sign-In',
        linkContainerSelectors: [
          '.membership-entry__button--login',
          '.mobile-menu-toggle--profile',
          '.o-header__links--login',
          '.o-mobile-header__login',
        ],
      },
      {
        linkContainerName: 'component::Modal::Sign-Up-Button',
        linkContainerSelectors: ['.m-form-login__sign-up-button'],
      },
      {
        linkContainerName: 'component::Modal::Have-Account',
        linkContainerSelectors: ['.o-sign-in-modal__sign-up__to-login'],
      },
      {
        linkContainerName: 'component::Modal::Log-In-Button',
        linkContainerSelectors: ['.m-form-login__login-button'],
      },
      {
        linkContainerName: 'component::Modal::New-User',
        linkContainerSelectors: ['.o-sign-in-modal__login__to-sign-up'],
      },
      {
        linkContainerName: 'component::sitefinder',
        linkContainerSelectors: ['.o-casino-match'],
      },
      {
        linkContainerName: 'component::toplist-table',
        linkContainerSelectors: ['.m-data-table'],
      },
      {
        linkContainerName: 'component::free-games',
        linkContainerSelectors: ['.o-games-library', '.o-games-cards-filters'],
      },
      {
        linkContainerName: 'bottomCTA',
        linkContainerSelectors: ['.bottom-cta'],
      },
      {
        linkContainerName: 'component::related-boxes',
        linkContainerSelectors: ['.related-boxes'],
      },
      {
        linkContainerName: 'component::info-cards',
        linkContainerSelectors: ['.o-info-cards'],
      },
      {
        linkContainerName: 'component::freegames',
        linkContainerSelectors: ['#freeGames', '.free-games'],
      },
      {
        linkContainerName: 'component::language-interlinker',
        linkContainerSelectors: ['.interlinker'],
      },
      {
        linkContainerName: 'component::free-games-quick-filter',
        linkContainerSelectors: ['.o-games-cards-filters'],
      },
      {
        linkContainerName: 'component::MiniToplist-Review',
        linkContainerSelectors: ['.m-partner-card-mini__cta--review'],
      },
      {
        linkContainerName: 'component::MiniToplist-Review',
        linkContainerSelectors: ['.m-partner-card-mini__link-overlay'],
      },
      {
        linkContainerName: 'component::TableOfContent',
        linkContainerSelectors: ['.o-table-of-content__link'],
      },
      {
        linkContainerName: 'Toplist::MoreDetails',
        linkContainerSelectors: ['.toplist-details__more-info'],
      },
      {
        linkContainerName: 'Toplist::LessDetails',
        linkContainerSelectors: ['.toplist-details__more-info__dropdown'],
      },
      {
        linkContainerName: 'Toplist::ReviewLoadMore',
        linkContainerSelectors: ['.o-toplist__loadMore'],
      },
      {
        linkContainerName: 'component::ReviewLinks',
        linkContainerSelectors: ['.m-review-card__rating'],
      },
      {
        linkContainerName: 'OToplist::AllReviews',
        linkContainerSelectors: ['.o-toplist__reviews'],
      },
      {
        linkContainerName: 'MGameCard::PlayFree',
        linkContainerSelectors: ['.m-game-card__cta--play-free'],
      },
      {
        linkContainerName: 'MGameCard::PlayMoney',
        linkContainerSelectors: ['.m-game-card__cta--play-money'],
      },
      {
        linkContainerName: 'MPartnerCardMini::Review',
        linkContainerSelectors: ['.m-partner-card-mini__cta--review'],
      },
      {
        linkContainerName: 'MPartnerCardMini::Partner',
        linkContainerSelectors: ['.m-partner-card-mini__link-overlay', '.m-partner-card-mini__cta--partner'],
      },
      {
        linkContainerName: 'OInteractiveMap::CasinoTab',
        linkContainerSelectors: ['.o-interactive-map__casino-tab'],
      },

      {
        linkContainerName: 'OInteractiveMap::SportsTab',
        linkContainerSelectors: ['.o-interactive-map__sports-tab'],
      },

      {
        linkContainerName: 'OInteractiveMap::Cta',
        linkContainerSelectors: ['.o-interactive-map__cta'],
      },
      {
        linkContainerName: 'OCompareTable::PlayNow',
        linkContainerSelectors: ['.o-compare-table__button-wrapper'],
      },
      {
        linkContainerName: 'OCompareTable::AddCasino',
        linkContainerSelectors: ['.o-compare-table__empty'],
      },
      {
        linkContainerName: 'OCompareTable::ViewMore',
        linkContainerSelectors: ['.o-compare-table__more-casinos__button'],
      },
      {
        linkContainerName: 'component::dropdown',
        linkContainerSelectors: ['.m-dropdown'],
      },
      {
        linkContainerName: 'm-desktop-atf-dropdown-links__sublink',
        linkContainerSelectors: ['.m-dropdown'],
      },
      {
        linkContainerName: 'OInPageNav::Desktop',
        linkContainerSelectors: ['.o-in-page-nav__desktop-sidebar'],
      },
      {
        linkContainerName: 'OAtfReviews::LikeButtonDesktop',
        linkContainerSelectors: ['.o-atf-reviews__like-button'],
      },
      {
        linkContainerName: 'OAtfReviews::LikeButtonMobile',
        linkContainerSelectors: ['.o-atf-reviews__like-button--mobile'],
      },
      {
        linkContainerName: 'OAtfReviews::Afflink',
        linkContainerSelectors: ['.o-atf-reviews__cta'],
      },
      {
        linkContainerName: 'MStickyBonus::BottomBannerAfflink',
        linkContainerSelectors: ['.m-sticky-bonus__button'],
      },
      {
        linkContainerName: 'MJackpotBanner::BannerCtaAfflink',
        linkContainerSelectors: ['.m-jackpot-banner__button'],
      },
      {
        linkContainerName: 'OAtfHero::HpButton',
        linkContainerSelectors: ['.o-atf-hero-cta__button'],
      },
      {
        linkContainerName: 'MBreadcrumbs::Link',
        linkContainerSelectors: ['.m-breadcrumbs__list'],
      },
      {
        linkContainerName: 'MGameBanner::Link',
        linkContainerSelectors: ['.m-game-banner__link'],
      },
      // exp corg-11313
      {
        linkContainerName: 'OLiveGameCards::CTA',
        linkContainerSelectors: ['.m-live-game-card__cta--partner'],
      },
      // exp corg-10926
      {
        linkContainerName: 'OGamesFinderModal::shuffleClicked',
        linkContainerSelectors: ['.o-games-finder-modal__button'],
      },
      {
        linkContainerName: 'OGamesFinderModal::Close',
        linkContainerSelectors: ['.o-games-finder-modal__close'],
      },
      {
        linkContainerName: 'OGamesFinderModal::playButton',
        linkContainerSelectors: ['o-games-finder-modal__play-button'],
      },
      {
        linkContainerName: 'MMobileSearchFilters::gameFinderButton',
        linkContainerSelectors: ['.m-mobile-search-filters__games-finder-btn'],
      },
      {
        linkContainerName: 'MDesktopSearchFilters::gameFinderButton',
        linkContainerSelectors: ['.m-desktop-search-filters__games-finder-btn'],
      },
      // exp corg-10926 END
      // exp corg-11542
      {
        linkContainerName: 'OGamesFinder::shuffleClicked',
        linkContainerSelectors: ['.o-games-finder__button'],
      },
      {
        linkContainerName: 'OGamesFinder::Close',
        linkContainerSelectors: ['.o-games-finder__close'],
      },
      {
        linkContainerName: 'OGamesFinder::playButton',
        linkContainerSelectors: ['.o-games-finder__play-button'],
      },
      // exp corg-11542 END
      // exp corg-11602
      {
        linkContainerName: 'MAnchorLinks:Link',
        linkContainerSelectors: ['.m-anchor-links__links-list-scroll'],
      },
      {
        linkContainerName: 'OAtfReviews::BonusLink',
        linkContainerSelectors: ['.o-atf-review__bonus'],
      },
      {
        linkContainerName: 'MImageCarousel::ArrowButtons',
        linkContainerSelectors: ['.m-image-carousel'],
      },
      // exp corg-12922
      {
        linkContainerName: 'OAtfSlots::PayForFreeButton',
        linkContainerSelectors: ['.o-atf-slots__cta--free'],
      },
      {
        linkContainerName: 'OAtfSlots::AfflinkButton',
        linkContainerSelectors: ['.o-atf-slots__cta--afflink'],
      },
      {
        linkContainerName: 'OAtfSlots::AuthorLink',
        linkContainerSelectors: ['.o-atf-slots__author-tracking'],
      },
      {
        linkContainerName: 'OAtfSlots::Toplist',
        linkContainerSelectors: ['.o-atf-slots__toplist-tracking'],
      },
      // exp corg--11290
      {
        linkContainerName: 'OGamesCards::GamesFreeLink',
        linkContainerSelectors: ['.o-game-cards__games-free-link'],
      },
      // exp corg-8444
      {
        linkContainerName: 'OSlotsMatch::PlayGame',
        linkContainerSelectors: ['.m-game-card__cta--slots-finder'],
      },
      // exp corg-14724
      {
        linkContainerName: 'tractus:clickout',
        linkContainerSelectors: ['.o-atf-review__tractus-cta'],
      },
      // exp corg-12440
      {
        linkContainerName: 'OAtfIntro::GreenBadgelink',
        linkContainerSelectors: ['.o-atf-intro__green-badge__link'],
      },
      // exp corg-12644
      {
        linkContainerName: 'OWinnersFeed::PartnerLink',
        linkContainerSelectors: ['.o-winners-feed__cta'],
      },
      // exp corg-12965
      {
        linkContainerName: 'OCasinoMatchPopup::SubmitQuestions',
        linkContainerSelectors: ['.o-casino-match__button-submit'],
      },
      {
        linkContainerName: 'OCasinoMatchPopup::ChangePreference',
        linkContainerSelectors: ['.o-casino-match__button-change-preference'],
      },
      {
        linkContainerName: 'OCasinoMatchPopup::PartnerLink',
        linkContainerSelectors: ['.o-casino-match__partner-tracking'],
      },
      {
        linkContainerName: 'OCasinoMatchPopup::PartnerReview',
        linkContainerSelectors: ['.o-casino-match__review-tracking'],
      },
      {
        linkContainerName: 'OCasinoMatch::PartnerLink',
        linkContainerSelectors: ['.o-casino-match__partner-link-tracking'],
      },
      {
        linkContainerName: 'OCasinoMatch::PartnerReview',
        linkContainerSelectors: ['.m-casino-match-partner-card__button-wrapper__review'],
      },
      // exp corg-13980
      {
        linkContainerName: 'OHeader::NotificationPartnerLink1',
        linkContainerSelectors: ['.notification-partner-0'],
      },
      {
        linkContainerName: 'OHeader::NotificationPartnerLink2',
        linkContainerSelectors: ['.notification-partner-1'],
      },
      {
        linkContainerName: 'OHeader::NotificationPartnerLink3',
        linkContainerSelectors: ['.notification-partner-2'],
      },
      // experiment corg-14711
      {
        linkContainerName: 'OAtfIntro::ParagraphPartnerLink',
        linkContainerSelectors: ['.o-atf-intro__paragraph-partner-link'],
      },
      // experiment corg-14711 END
      {
        linkContainerName: 'MGamesLightbox::PartnerLink',
        linkContainerSelectors: ['.m-game-lightbox__footer-banner__partner-link'],
      },
      {
        linkContainerName: 'MGameLightbox::PartnerReview',
        linkContainerSelectors: ['.m-game-lightbox__footer-banner__partner-review'],
      },
      {
        linkContainerName: 'MHighlightedInfoSegment::ReviewTextLink',
        linkContainerSelectors: ['.m-highlighted-info-segment__content'],
      },
      // exp corg-10890-v1 & corg-10890-v2
      {
        linkContainerName: 'OGamesLibrary::RecommendedGamesTab',
        linkContainerSelectors: ['.o-games-library__tab--recommended'],
      },
      {
        linkContainerName: 'OGamesLibrary::AllGamesTab',
        linkContainerSelectors: ['.o-games-library__tab--all'],
      },
      // exp corg-16330  && corg-16342
      {
        linkContainerName: 'OAtfGamesBanner::PlayForFreeButton',
        linkContainerSelectors: ['.o-atf-games-banner__game__button'],
      },
      {
        linkContainerName: 'OAtfGamesBanner::CarouselNavClick',
        linkContainerSelectors: ['.o-atf-games-banner__carousel__nav'],
      },
      {
        linkContainerName: 'OAtfHero::HpButton',
        linkContainerSelectors: ['.o-atf-hero-cta__link'],
      },
      {
        linkContainerName: 'OHomepageToplist::PartnerLink',
        linkContainerSelectors: ['.o-homepage-toplist__tracking'],
      },
      {
        linkContainerName: 'OHomepageToplist::AllBonuses',
        linkContainerSelectors: ['.o-homepage-toplist__button'],
      },
      {
        linkContainerName: 'OGames::HpButton',
        linkContainerSelectors: ['.o-homepage-games__button'],
      },
      {
        linkContainerName: 'OReviewCards::AllReviews',
        linkContainerSelectors: ['.o-review-cards__button'],
      },
      {
        linkContainerName: 'OReviewCards::ReviewLink',
        linkContainerSelectors: ['.o-review-cards__tracking'],
      },
      {
        linkContainerName: 'OAtfHero::HpButton-Games',
        linkContainerSelectors: ['.o-atf-hero-cta__link--free-games'],
      },
      {
        linkContainerName: 'OAtfHero::HpButton-Reviews',
        linkContainerSelectors: ['.o-atf-hero-cta__link--reviews'],
      },
      {
        linkContainerName: 'OAtfHero::HpButton-News',
        linkContainerSelectors: ['.o-atf-hero-cta__link--news'],
      },
      {
        linkContainerName: 'OAtfHero::HpButton-Geo',
        linkContainerSelectors: ['.o-atf-hero-cta__link--country'],
      },
      // exp corg-18381
      {
        linkContainerName: 'RealMoney::Interlink',
        linkContainerSelectors: ['.m-nav-card-large__interlink'],
      },
      // exp corg-18381 END
      // exp corg-18731
      {
        linkContainerName: 'RealMoney::LinkBanner',
        linkContainerSelectors: ['.header__us-link'],
      },
      // exp corg-18731 END
    ],
    linkContainerDatasetName: 'aacTracker',
    outboundLinkFolder: `^${window.location.origin}\/casinos\/.*|^https:\/\/track\.casino\.org\/.*`,
    domainHash,
    pageTagLocalNames: [
      'language',
      'country',
      'main_category',
      'sub_categories',
      'vertical',
      'sub_vertical',
      'tri_vertical',
      'toplist',
      'primarySubCategory',
      'equivalent_page',
      'toplist_data_ids',
      'partner_data_ids',
      'variant',
      'state',
      'platform',
      'event',
      'site_section',
    ],
    transactionIdSiteNumber: '1300',
    userTagLocalNames: ['mobile', 'tablet', 'user_continent', 'user_country', 'user_state', 'user_city'],
    breadcrumbsSelector: '.corg-breadcrumbs > li',
    searchParam: 'q',
    debug: debugEnabled,
  }
}
