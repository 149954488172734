
import merge from '~lodash.mergewith'
import { init, vueRouterInstrumentation, BrowserTracing } from '~@sentry/vue'
import * as CoreSdk from '~@sentry/core'
import * as BrowserSdk from '~@sentry/browser-sdk'
import { CaptureConsole, ExtraErrorData, ReportingObserver, RewriteFrames } from '~@sentry/integrations'

export { init }
export const SentrySdk = { ...CoreSdk, ...BrowserSdk }

export function getConfig (ctx) {
  /* eslint-disable object-curly-spacing, quote-props, quotes, key-spacing, comma-spacing */
  const config = {
    dsn:"https:\u002F\u002F00fa81b807bf47e39ba28212e8c6719e@o542129.ingest.sentry.io\u002F5666764",
    environment:"production",
    debug:false,
    release:"3d31d740a8ad329acb69d4d27bb5b8c5b4ae95fb",
    ignoreErrors:["[object Event]","Missing external script","ECONNRESET","TypeError: document.head.append is not a function","Non-Error promise rejection captured with","Non-Error exception captured with keys","\u003Cunknown\u003E"],
    tracesSampleRate:0.1,
  }

  config.integrations = [
    new ExtraErrorData(),
    new ReportingObserver({ types:["crash","crash"] }),
    new RewriteFrames(),
    new CaptureConsole({ levels:["error"] }),
  ]

  const { browserTracing, vueOptions, vueRouterInstrumentationOptions, ...tracingOptions } = {"tracesSampleRate":0.1,"browserTracing":{},"vueOptions":{"trackComponents":true,"tracing":true,"tracingOptions":{"hooks":["activate","mount","update"],"timeout":2000,"trackComponents":true}},"vueRouterInstrumentationOptions":{"routeLabel":"name"},"browserOptions":{"tracingOrigins":[new RegExp("^\\\u002F", "")]}}
  config.integrations.push(new BrowserTracing({
    ...(ctx.app.router ? { routingInstrumentation: vueRouterInstrumentation(ctx.app.router, vueRouterInstrumentationOptions) } : {}),
    ...browserTracing,
  }))
  merge(config, vueOptions, tracingOptions)

  const runtimeConfigKey = "sentry"
  if (ctx.$config && runtimeConfigKey && ctx.$config[runtimeConfigKey]) {
    merge(config, ctx.$config[runtimeConfigKey].config, ctx.$config[runtimeConfigKey].clientConfig)
  }

  return config
}
